import React from "react";
import {  graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";
import Arrow from "../static/svg/arrow.inline.svg";
import HeroImgInline from "../static/svg/hero-image.inline.svg";

import Layout from "../components/layout";
import SEO from "../components/seo";
import VoiceShort from "../components/voiceShort";
import BusinessCalendar from "../components/businessCalendar";
import Recommend from "../components/recommend";
import Info from "../components/info";
import InfoLink from "../components/infolink";
import Cta from "../components/cta";
import BlogCardsCol from "../components/blogCardsCol";


function IndexPage({ location }) {
  const {pathname} = location;

  return (
    <Layout lang="ja">
      <SEO pageurl={pathname} />

      <section className="text-center">
      <div className="text-center -mt-6 mb-5  xxs:-mt-10 md:my-14"><HeroImgInline className=" inline-block max-w-1/2 xs:max-w-2/5 md:max-w-[250px] " /></div>

        <div className="text-sm text-left bg-c-main m-0"><h1 className="mt-0 mb-10 py-1.5 text-center text-c-base text-base md:text-lg">姿勢に自信ができると気持ちも上向きます</h1></div>

        <div className="text-left mx-1 md:mx-4 ">
        <div  className=" my-2 p-2 mx-0.5  border border-c-main">
<p className="text-lg  font-bold mt-6 ">しつこい肩こり、重い腰、<br />頭のつまり感…</p>
<div className="overflow-hidden">

<StaticImage
  src="../images/makigata.png"
  alt="猫背"
  placeholder="blurred"
  layout="constrained"
  className="float-left mr-5  mt-4 mb-4 ml-4 max-w-[50px]"
/>
<p>巻き肩のせいかな？<br />姿勢が悪いから？< br/>
もっと悪くなったら<span className="xs:hidden"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>どうしよう…</p></div><p>スッキリ解消しませんか？</p>

<hr className="my-10 border-c-main" />

<p className="mb-0 text-lg font-bold">元ITエンジニアが惚れこんだ、<br />アメリカ式整体オステオパシー</p>
<p>オステオパシーは、アメリカの医師が創始した、理論に基づく手技です。</p>

<ul className="ml-6 list-[square]">
  <li>姿勢</li>
  <li>体の動き</li>
  <li>触診</li>
</ul>
をもとにして、
<ul className="ml-6 list-[square]">
  <li>解剖生理学の知識</li>
  <li>オステオパシーの哲学</li>
</ul>
<p>
から、あなたの不調を読みときます。</p>
<p>
<Link to='/osteopathy/' className="underline text-blue-800 hover-hover:hover:text-blue-900">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline pb-0.5" />オステオパシーについてもっと読む
          </Link>
          </p>

<hr className="my-10 border-c-main" />


<p className="text-lg font-bold">痛いのが苦手ですか？</p>

<ul className="ml-6 list-disc list-outside">
    <li>グイグイおされると揉み返しがくる</li>
    <li>ポキッとくるかなと思うと体に力がはいってしまう…</li>
</ul>
<div className="overflow-hidden">

<StaticImage
  src="../images/sleeping.png"
  alt="寝てしまう"
  placeholder="blurred"
  layout="constrained"
  className="float-right xs:float-left mr-2 mt-3 mb-4 ml-2 max-w-[50px]"
/>
<p>私もグイグイ強い施術は苦手です。<br />
ぺんぎん堂の施術は、眠ってしまう人も多いくらい優しいですよ。</p>
<p><Link to='/therapy/' className="underline text-blue-800 hover-hover:hover:text-blue-900">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline pb-0.5" />当院の施術についてもっと読む
          </Link></p>
</div>
</div>


         

          

          


          <div className="text-sm text-left bg-c-main my-0"><h2 className="mt-10 mb-3  p-1 text-center text-c-base">プロフィール</h2></div>
          <div className="text-left mx-3 mb-14 mt-8 md:mx-4">
          <p>こんにちは。ぺんぎん堂の橋本智子です。</p>


          <div className="overflow-hidden">
            <StaticImage
              src="../images/seitai-session1.jpg"
              alt="整体・オステオパシー施術の様子"
              placeholder="blurred"
              layout="constrained"
              width={400}
              className="lg:float-left mr-5 mt-3 mb-4 ml-2"
            />

         

          
          <p>”アメリカ式整体オステオパシー” という方法で体の不調を和らげるお手伝いをしております。</p>
          <p>「どうして ”オステオパシー” ？」<br />少しだけ私の話をさせてください。</p>
          <hr className="my-8 border-c-main" />
          <p>元々、金融機関でITエンジニアをしていたのですが、自分の体や家族の体の不調から整体に興味を持ちました。</p>
          <p>当初は、体の流れを読む、という整体を学んだりもしました。エンジニア思考が抜けきらないのか、私の性格か、</p><ul className="list-disc ml-6"><li>こうやればうまくいくことが多い</li><li>「なぜ？」は考えずとにかく実践！</li></ul><p>という整体には、なにか納得がいきませんでした。</p><p>本を読んだり、整体を受けてみたり試行錯誤しました。</p>
          <p className="my-6 mx-3"><span className="c-marker text-lg">そんな私が行きついたのが、<span className="sm:hidden"><br /></span>アメリカ式整体<span className="lg:hidden"><br /></span>&nbsp;”オステオパシー”&nbsp;です。</span></p>
          <p>触診・動き・姿勢などをみて、解剖生理学とオステオパシーの哲学を元に分析して施術する方法です。</p>
          <p>「なぜ？」「何が原因？」がわかると、</p><ul className="list-disc ml-6"><li>整体でできること</li><li>日常生活で気を付けること</li><li>どうやって姿勢を保って予防するか</li></ul><p>などが、見えてきます。</p>
          <Link to='/profile/' className="mt-2">
            <p className="mb-0  text-lg underline text-blue-800 hover-hover:hover:text-blue-900">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline  text-lx pb-0.5" />
              プロフィールをもっと読む
              </p></Link>
          </div>
          </div>



          <div className="text-sm text-left bg-c-main mt-14 m-0"><h2 className="mt-14 mb-8  p-1 text-center text-c-base">施術コース・料金</h2></div>

          <div className="text-left mx-3 md:mx-4 mt-8">
<p>施術コースは2種類、</p><ul className="list-decimal ml-6"><li>初めての方向け</li><li>2回目以降のオステオパシーコース</li></ul><p>です。</p>
<p>2回目以降はシンプルにコースはひとつ。</p><p>あなたのその日の体の状態から、</p>
  <ul className="list-disc"><li>リンパが中心の施術のことも、</li>
  <li>頭蓋が中心の施術の事も</li></ul>
  <p>あります。</p><p>ですので、リンパコース、頭蓋コースなどは作らずに、毎回全身をチェックして、その日に重点をおく場所を決めます。</p>


<p className="mt-8"><span className="c-marker">【施術の流れ】</span></p>
<p className="mb-4">初回：約90分<br />
2回目以降：約60分</p>



<div className="rounded border-2 border-c-main mx-2 md:mx-4 my-2">
            <p className="bg-c-main text-c-base pl-2">チェックシートに記入（初回のみ）</p><p>お体のお悩みなどをチェックシートに記入していただきます。わかる範囲で大丈夫です。</p><p> 初回5分</p></div>

            <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

            <div className="rounded border-2 border-c-main mx-2 md:mx-4 my-2">

            <p className="bg-c-main text-c-base pl-2">お着替え</p><p>スカートやタイトなパンツでいらしたかたは、ゆったりした服装に着替えていただきます。こちらにもお着替えの用意がございます。</p><p>3分</p></div>

<div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

<div className="rounded border-2 border-c-main mx-2 md:mx-4 my-2">

            <p className="bg-c-main text-c-base pl-2">お話を伺います。</p><p>どこが痛いのか、どんな時に痛いのかなどをお伺いします。気になることはご遠慮なくお話してくださいね。</p><p> 初回10~15分、2回目以降5~10分</p></div>

<div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

<div className="rounded border-2 border-c-main mx-2 md:mx-4 my-2">

<p className="bg-c-main text-c-base pl-2">体の検査</p><p>どこが悪いのかを細かく検査します。細かくチェックすることで、お体の状態を把握して施術する方針を決めていきます。</p><p> 初回10~15分、2回目以降5分~10分</p></div>

<div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

<div className="rounded border-2 border-c-main mx-2 md:mx-4 my-2">

<p className="bg-c-main text-c-base pl-2">施術</p><p>検査した結果をもとに、施術します。軽く揺らしたり、力をつかわず筋肉をゆるめたりするので、リラックスして受けていただけますよ。</p><p> 40分~45分</p></div>

<div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>


<div className="rounded border-2 border-c-main mx-2 md:mx-4 my-2">

<p className="bg-c-main text-c-base pl-2">お体の説明とアドバイス</p><p>検査結果はどうだったのか、なにを施術したかをお話しします。日常生活で気を付ける点、座り方など姿勢のアドバイスもします。気になることはご遠慮なく聞いてくださいね。</p><p>初回10~15分、2回目以降5分</p></div>
<hr className="mt-10  bg-c-sub border-c-sub " />
<p  className="mt-4 mb-4"><span className="c-marker">【コースと料金】</span></p>
<p>コースはシンプル、初回と2回目以降の２つです。</p>
<p className="my-1 font-semibold">初回オステオパシーコース</p>
            <p className="mt-1 mb-3 ml-10">施術料：8,800円<br />時間：約90分</p>
            <p className="my-1 font-semibold">2回目以降オステオパシーコース</p>
            <p className="mt-1 mb-3 ml-10">施術料：6,600円<br />時間：約60分</p>


            </div>
            <div className="text-sm text-left bg-c-main m-0"><h2 className="mt-14 mb-8  p-1 text-center text-c-base">営業日</h2></div>
            <p>月・火・木・第三土曜日に営業しております。</p>
            {/* <p>9/16（敬老の日）はお休みをいただきます。</p> */}
            <p>11月は第4土曜日の11/23に営業いたします。</p>
          <BusinessCalendar />

            <InfoLink />
            <Cta  idForGtm="cta-view"/>



          <VoiceShort />
          <hr className="my-2" />



          <div className="overflow-hidden">

            <StaticImage
              src="../images/seitai-session2.jpg"
              alt="背骨の検査"
              placeholder="blurred"
              layout="constrained"
              className="max-w-17 sm:float-left mr-5 mt-3 mb-4 ml-2 w-full"
            />
            </div>



            <Recommend />

  <Cta  idForGtm="cta-view"/>



<Info />
<Cta />
<div className="text-sm text-left bg-c-main m-0"><h2 className="mt-14 mb-8  p-1 text-center text-c-base">コラム：姿勢と健康、セルフケア</h2></div>
<BlogCardsCol targetSlugs={["what-is-good-posture/", "importance-of-cranial-correction/","workfromhome-back-pain/","sedantary-lifestyle-back-pain/"]} />
<p className=" text-right lg:text-left mb-10"><Link to='/blog/' className="underline text-blue-800 hover-hover:hover:text-blue-900">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline pb-0.5" />コラムをもっと読む
          </Link></p>
</div>

      </section>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
` 